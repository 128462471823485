<template>
  <div class="login-form-content flex-column">
    <span class="login-slogan font-size-36">{{companyBasicInfo.login_slogan}}</span>
    <span class="font-size-16" style="margin-top: 3rem">账号</span>
    <div class="input-wrapper flex-row">
      <span
        :class="[
          'iconfont',
         'iconzhanghao1',
          'account-img',
        ]"
      ></span>
      <input
        class="input font-size-16"
        type="text"
        v-model="userForm.username"
        ref="accountInput"
        placeholder="请输入手机号/邮箱"
        @keyup.enter="onInputEnter"
      />
    </div>
    <span class="font-size-16" style="margin-top: 1.375rem">{{
      mode === 1 ? "密码" : "验证码"
    }}</span>
    <div class="flex-row">
      <div
        :class="[
          mode === 1 ? 'input-wrapper' : 'code-input-wrapper',
          'flex-row',
        ]"
      >
        <span
          :class="[
            'iconfont',
            mode === 1 ? '' : '',
            'account-img',
          ]"
        ></span>
        <input
          class="input font-size-16"
          :type="mode === 1 ? 'password' : 'tel'"
          v-model="userForm.password"
          ref="passwordInput"
          :placeholder="getPasswordInputPlaceholder()"
          @keyup.enter="onInputEnter"
        />
      </div>
      <CodeButton
        v-if="mode === 0"
        style="margin-top: 1.125rem"
        @sendCodeFun="sendCodeFun"
      />
    </div>
    <div
      class="big-btn font-size-18 flex-all-center pointer white"
      @click="onClickLogin()"
    >
      欢迎登录
    </div>
    <div class="flex-row-space-between" style="margin-top: 1.125rem">
      <span class="font-size-16 pointer" @click="onClickLeft()">{{
        mode === 1 ? "忘记密码？" : ""
      }}</span>
      <span class="font-size-16 pointer" @click="onClickRight()">{{
        mode === 1 ? "验证码登录" : "密码登录"
      }}</span>
    </div>
    <div class="flex-row-space-between" style="margin-top: 3.75rem">
      <div class="line-wrapper"></div>
      <span class="font-size-16">扫码登录</span>
      <div class="line-wrapper"></div>
    </div>
    <div
      class="wx-wrapper flex-all-center flex-align-self-center pointer"
      @click="onClickWx()"
    >
      <span class="iconfont iconweixin wx-img"></span>
    </div>
  </div>
</template>
<script>
import CodeButton from "@components/CodeButton";
import {isPhoneOrEmail,isCanLogin} from "@utils/login.js";
export default {
  name: "LoginForm",
  props:{
    review:{
      type:Boolean,
      defalut:false
    }
  },
  data() {
    return {
      userForm: {
        username: "",
        code: "",
        password: "",
        platform: "",
      },
      mode: 0, //0.验证码登录，1.密码登录
    };
  },
  computed:{
    companyBasicInfo(){
      return this.$store.state.user.companyBasicInfo;
    }
  },
  methods: {
    getPasswordInputPlaceholder() {
      return this.mode === 1 ? "请输入密码" : "请输入短信/邮件验证码";
    },
    sendCodeFun(callback) {
      if(this.review) return;
      let platform = isPhoneOrEmail(this.userForm.username);
      if (platform !== "") {
        this.userForm.platform = platform;
        //发送验证码
        this.$emit("sendCodeFun", this.userForm);
        callback();
      }
    },
    onClickLogin() {
      if(this.review) return;
      let platform = isCanLogin(this.userForm,this.mode);
      if(platform != ''){
          this.userForm.platform = platform;
          if(this.mode === 0){
              this.userForm.code = this.userForm.password;
              this.userForm.password = '';
          }else if(this.mode===1){
              this.userForm.code = '';
          }

          this.$emit('sendLogin',this.userForm);
      }
    },
    onClickLeft() {
      if(this.review) return;
      this.$emit("forgetPwFun");
    },
    onClickRight() {
      this.userForm.password = "";
      this.mode = this.mode === 0 ? 1 : 0;
    },
    onClickWx() {
      if(this.review) return;
      this.$emit("toWeixinFun");
    },
    onInputEnter() {},
  },
  components: {
    CodeButton,
  },
};
</script>
<style lang="scss" scoped>
.login-form-content {
  display: flex;
  width: auto;
  .login-slogan{
    display:-webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    overflow:hidden;
  }
  .input-wrapper {
    margin-top: 18px;
    width: 100%;
    height: 54px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .account-img {
      font-size: 24px;
      color: #868c97;
      margin: 0 10px 0 10px;
    }
    .input {
      width: 84%;
      height: 98%;
      border: 0;
      outline: none;
    }
  }
  .code-input-wrapper {
    @extend .input-wrapper;
    width: 306px;
    margin-right: 12px;
  }
  .line-wrapper {
    width: 148px;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
  .wx-wrapper {
    margin-top: 34px;
    width: 48px;
    height: 48px;
    background: #1ba55f;
    border-radius: 50%;
    .wx-img {
      font-size: 30px;
      color: white;
    }
  }
}
</style>
