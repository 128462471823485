<template>
  <div class="login-content">
    <img class="logo-img" :src="fileUrl" />
    <div class="content-wrapper flex-all-center">
      <div class="login-con">
        <LoginForm :review="true"></LoginForm>
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from "../login/components/LoginForm";
export default {
  name: "Login",

  components: {
    LoginForm
  },
  data(){
    return{
      fileUrl:''
    }
  },
  computed:{
    previewLoginBackground(){
      return this.$store.state.user.fileUrl
    },
    companyBasicInfo(){
      return this.$store.state.user.companyBasicInfo;
    }
  },
  created(){
    this.fileUrl = localStorage.getItem('fileUrl')|| this.companyBasicInfo.login_background;
  }
};
</script>
<style lang="scss" scoped>
.login-content {
  display: flex;
  width: 100%;
  height: 100vh;
  .logo-img {
    width: 45%;
    height: 100%;
    background: linear-gradient(90deg, #3a49fe, #336efe);
    object-fit: cover;
  }
  .content-wrapper {
    width: 55%;
    .login-con {
      width:446px;
      min-width: 300px;
    }
  }
}
</style>
